@font-face {
  font-family: "QuidProSans-Regular";
  src: url("./Assets/QuidProSans-Regular.eot"); /* IE9 Compat Modes */
  src: url("./Assets/QuidProSans-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./Assets/QuidProSans-Regular.otf") format("opentype"),
    url("./Assets/QuidProSans-Regular.woff") format("woff"), /* Modern Browsers */
    url("./Assets/QuidProSans-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

$gold: #B09154;

* {
  box-sizing: border-box;
}

body {
  font-family: 'BFTinyHand-Regular', sans-serif;
  font-size: 18px;
}

.capitalized {
  font-family: proxima-nova, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  color: $gold;
}
.main {
  position: relative;
  margin: 0 auto;
  max-width: 1080px;
  width: 100%;
  background-color: white;

  .wrapper {
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
