@import '../../App.scss';

.hidden {
  display: none;
}

.tiny-hands-form {
  height: 100%;
  position: relative;
  flex: 1;
  background-color: white;
  textarea {
    padding: 0 50px;
    position: absolute;
    background-color: white;
    font-family: 'QuidProSans-Regular', sans-serif;
    font-size: 2.5rem;
    @media (min-width: 768px) {
      font-size: 3.5rem;
    }
    @media (min-width: 1024px) {
      font-size: 5.5rem;
    }
    // padding: 0 20px;
    border: none;
    resize: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 1080px;
    width: 100%;
    &::placeholder {
      color: black;
      letter-spacing: -.125rem;
    }
    &:focus {
      outline: none;
    }
  }
}
