@import '../../App.scss';


.footer {
  display: flex;
  position: fixed;
  bottom: 50px;
  justify-content: space-around;
  @media (min-width: 1024px) {
    left: 20px;
    right: 20px;
  }
  max-width: 1080px;
  // width: 100%;
  margin: 0 auto;
  button.footer-link {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: .8rem;
    &:focus {
      outline: none;
    }
    @media (min-width: 768px) {
      font-size: 1rem;
    }
    @media (min-width: 1024px) {
      font-size: 1.125rem;
    }
  }
}
