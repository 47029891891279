@import '../../App.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background-color: white;
  width: 100%;
  z-index: 2;
  @media (min-width: 1024px) {
    padding: 50px 0;
  }
  img {
    max-height: 3.5rem;
    @media (min-width: 768px) {
      max-height: 5rem;
    }
    @media (min-width: 1024px) {
      max-height: 6.5rem;
    }
  }
  h1 {
    font-size: 1.25rem;
    @media (min-width: 768px) {
      font-size: 2rem;
    }
    @media (min-width: 1024px) {
      font-size: 2.5rem;
    }
  }
}
